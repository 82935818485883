import React from "react";
import {
  Filter,
  downloadCSV,
  TextInput,
  Responsive,
  SimpleList,
  List,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  UrlField,
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import jsonExport from "jsonexport";

export const WorkList = (props) => (
  <List
    filters={<InstanceFilter />}
    bulkActionButtons={false}
    exporter={exporter}
    {...props}
  >
    <Responsive
      small={
        <SimpleList
          primaryText={(record) => {
            return record.name;
          }}
          secondaryText={(record) => record.email}
          tertiaryText={(record) =>
            new Date(record.updatedAt).toLocaleDateString()
          }
        />
      }
      medium={
        <Datagrid rowClick="show">
          <DateField label="Created at" source="createdAt" />
          <DateField label="Modified at" source="updatedAt" />
          <TextField label="Name" source="name" />
          <EmailField label="Email" source="email" />
          <EmailField label="School Id" source="school_id" />
          <UrlField source="work_zip_url" />
        </Datagrid>
      }
    />
  </List>
);

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    const { ...postForExport } = post; // omit backlinks and author
    // for (let index = 0; index < postForExport.images.length; index++) {
    //     const element = postForExport.images[index];
    //     postForExport['image_'+index] = postForExport.images[index].url;
    // }
    // postForExport.author_name = post.author.name; // add a field
    return postForExport;
  });
  jsonExport(
    postsForExport,
    {
      headers: [
        "id",
        "name",
        "email",
        "resp_name",
        "resp_email",
        "resp_phone",
        "school_role",
        "description",
        "work_zip_url",
        "link_fb",
        "link_insta",
        "link_youtube",
        "terms",
        "district",
        "county",
        "school",
        "createdAt",
        "updatedAt",
        "grouping_schools"
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "works"); // download as 'posts.csv` file
    }
  );
};
const InstanceFilter = ({ ...props }) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Email" source="email" alwaysOn />
    <DateTimeInput source="from_date" label="Created after" />
    <DateTimeInput source="to_date" label="Created before" />
  </Filter>
);
